/** @jsx jsx */
import compact from "lodash/compact";
import { DateTime } from "luxon";
import React, { Fragment, memo } from "react";
import { loadable } from "shared/helpers/loadableComponent";
import { getRanges } from "shared/helpers/ranges";
import useAllocineSchedule, {
  MovieSchedule as MovieScheduleType,
} from "shared/hooks/useAllocineSchedule";
import { Dig } from "shared/utils/ts";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../../../__generated__/gatsby.types";

export type RelatedMovie = Exclude<
  Dig<PageQuery, ["event", "relatedMovies", number]>,
  undefined
>;

export type Theater = Exclude<
  Dig<PageQuery, ["event", "theaters", number]>,
  undefined
>;

const MovieSchedule = loadable(() => import("gatsby-theme-showtimes-grid"), {
  resolveComponent: (components) => components.MovieSchedule,
});

interface Props {
  movies: RelatedMovie["movies"];
  theater: Theater;
  startDate?: RelatedMovie["showtimesStartDate"];
  endDate?: RelatedMovie["showtimesEndDate"];
}

const ISO_OPTIONS = {
  suppressMilliseconds: true,
  includeOffset: false,
};

const RelatedMoviesSchedule: React.FC<Props> = ({
  movies,
  theater,
  startDate,
  endDate,
}) => {
  if (!movies || !theater || !theater.timeZone) {
    return null;
  }

  const ranges = getRanges(theater.showtimeWeekReleaseDay, theater.timeZone);
  const movieIds: string[] = compact(movies.map((movie) => movie?.id));

  let scheduleStartDate = ranges.ALL_WEEK[0];
  let scheduleEndDate: string | undefined = DateTime.fromISO(scheduleStartDate)
    .plus({ years: 1 })
    .toISODate();

  if (startDate) {
    scheduleStartDate = DateTime.fromSQL(startDate).toISO(ISO_OPTIONS);
    scheduleEndDate = endDate
      ? DateTime.fromSQL(endDate).toISO(ISO_OPTIONS)
      : undefined;
  }

  const { schedule } = useAllocineSchedule(
    theater.id,
    theater.timeZone,
    movieIds,
    scheduleStartDate,
    scheduleEndDate,
    true
  );

  return (
    <Fragment>
      {movies.map((movie) => {
        if (!movie) {
          return null;
        }

        const flatSchedule: MovieScheduleType = {};

        if (schedule?.[movie.id]) {
          Object.keys(schedule[movie.id]).forEach((date) => {
            if (schedule[movie.id][date].length) {
              flatSchedule[date] = schedule[movie.id][date];
            }
          });
        }

        return (
          <div key={movie.id}>
            <MovieSchedule
              key={movie.id}
              theater={theater}
              movie={movie}
              schedule={flatSchedule}
            />
          </div>
        );
      })}
    </Fragment>
  );
};

export default memo(RelatedMoviesSchedule);
