/** @jsx jsx */
import uniqueId from "lodash/uniqueId";
import React, { Fragment, memo } from "react";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../../../__generated__/gatsby.types";
import Schedule, { RelatedMovie, Theater } from "./Schedule";

type Event = Exclude<PageQuery["event"], null | undefined>;

interface Props {
  relatedMovies?: Event["relatedMovies"];
  theater?: Theater;
}

const RelatedMovies: React.FC<Props> = ({ relatedMovies, theater }) => {
  if (!relatedMovies || !theater || !theater.timeZone) {
    return null;
  }

  const flatRelatedMovies: RelatedMovie[] = [];

  relatedMovies.forEach((relatedMovie) => {
    if (relatedMovie?.movies?.length) {
      relatedMovie.movies.forEach((movie) => {
        flatRelatedMovies.push({
          movies: [movie],
          showtimesStartDate: relatedMovie.showtimesStartDate,
          showtimesEndDate: relatedMovie.showtimesEndDate,
        });
      });
    }
  });

  return (
    <Fragment>
      {flatRelatedMovies.map((relatedMovie) => {
        if (!relatedMovie?.movies) {
          return null;
        }
        return (
          <Schedule
            key={uniqueId("event-movies")}
            theater={theater}
            movies={relatedMovie.movies}
            startDate={relatedMovie?.showtimesStartDate}
            endDate={relatedMovie?.showtimesEndDate}
          />
        );
      })}
    </Fragment>
  );
};

export default memo(RelatedMovies);
