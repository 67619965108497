/** @jsx jsx */
import { graphql } from "gatsby";
import React, { Fragment, memo } from "react";
import { jsx, Themed } from "theme-ui";

import type { PageQuery } from "../../../../../__generated__/gatsby.types";
import RelatedMovies from "../../../../templates/Page/Event/RelatedMovies";
import WidgetWrapper from "../../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetEventLinkedMovies" }>;
  data: PageQuery["event"];
}

const EventRelatedMovies: React.FC<Props> = ({
  data,
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  if (!data?.relatedMovies?.length) {
    return null;
  }

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      {data.theaters?.map((theater) => (
        <Fragment key={theater?.id}>
          {(data.theaters?.length || 0) > 1 && (
            <Themed.h2>{theater?.name}</Themed.h2>
          )}
          <RelatedMovies
            relatedMovies={data.relatedMovies || undefined}
            theater={theater || undefined}
          />
        </Fragment>
      ))}
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetEventLinkedMovies on WidgetEventLinkedMovies {
    id
    __typename
  }
`;

export default memo(EventRelatedMovies);
